<template>
  <div class="bg-light">
    <!-- <table>
      <tr>
        <th class="text-center">{{ $t("POS") }}</th>
        <th class="text-center">{{ $t("TIPSTERS") }}</th>
        <th class="text-center">{{ $t("NO TIPS") }}</th>
        <th class="text-center">{{ $t("HIT") }}</th>
        <th class="text-center">{{ $t("PROFIT") }}</th>
      </tr>
      <tr v-for="data in tipsters" :key="data.rank">
        <td class="text-center">
          <p class="m-0 text-dark">{{ data.rank }}</p>
        </td>
        <td class="text-center">
          <a
            :href="`/soccer-tipsters/${sendName(data.name)}`"
            class="m-0 text-dark"
            style="color: #1c50ed !important"
            >{{ data.name.toUpperCase() }}</a
          >
        </td>
        <td class="text-center">
          <p class="m-0 text-dark">{{ data.profit }}</p>
        </td>
        <td class="text-center">
          <p class="m-0 text-dark">{{ data.profit }}</p>
        </td>
        <td class="text-center">
          <p class="m-0 text-dark">{{ data.profit }}</p>
        </td>
        <td class="text-center">
          <button class="btn btn-primary btn-sm">
            {{ $t("FOLLOW") }}
          </button>
        </td>
        <td class="text-center">
          <button
            class="btn btn-primary btn-sm m-0"
            data-toggle="collapse"
            :href="`#collapse${data.rank}`"
            role="button"
            aria-expanded="false"
            :aria-controls="`collapse${data.rank}`"
          >
            {{ $t("VIEW_MORE") }}
          </button>
        </td>
      </tr>
    </table> -->

    <div class="row">
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
        >
          <p class="m-0 text-dark">POS</p>
        </div>
      </div>

      <div class="col-lg-4">
        <div
          class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
        >
          <p class="m-0 text-dark">
            {{ $t("TIPSTERS") }}
          </p>
        </div>
      </div>
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ $t("NO_TIPS") }}</p>
        </div>
      </div>
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ $t("HIT") }}</p>
        </div>
      </div>
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ $t("PROFIT") }}</p>
        </div>
      </div>
    </div>

    <div class="row" v-for="data in tipsters" :key="data.rank">
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ data.rank }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <a
            :href="`/soccer-tipsters/${sendName(data.name)}`"
            class="m-0 text-dark"
            style="color: #1c50ed !important"
            >{{ data.name.toUpperCase() }}</a
          >
        </div>
      </div>
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ data.profit }}</p>
        </div>
      </div>
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ data.profit }}</p>
        </div>
      </div>
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ data.profit }}</p>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="d-flex align-items-center justify-content-between px-4">
          <button class="btn btn-primary btn-sm">
            {{ $t("FOLLOW") }}
          </button>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="d-flex align-items-center justify-content-between px-4">
          <button
            class="btn btn-primary btn-sm m-0"
            data-toggle="collapse"
            :href="`#collapse${data.rank}`"
            role="button"
            aria-expanded="false"
            :aria-controls="`collapse${data.rank}`"
          >
            {{ $t("VIEW_MORE") }}
          </button>
        </div>
      </div>
      <div class="col-lg-12 collapse" :id="`collapse${data.rank}`">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <div class="col-lg-2">
            <div class="card text-center">
              <div class="card-header p-0">ODDS</div>
              <div class="card-body p-0">
                <p class="card-title m-0">2.27</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="card text-center">
              <div class="card-header p-0">ROI</div>
              <div class="card-body p-0">
                <p class="card-title m-0">108.06%</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div
              class="card text-center"
              style="
                background-color: rgba(0, 108, 9, 0.638);
                border: 1px solid rgba(3, 87, 0, 0.633);
                color: white;
              "
            >
              <div
                class="card-header p-0"
                style="
                  background-color: rgba(0, 108, 9, 0.638);
                  border: 1px solid rgba(3, 87, 0, 0.633);
                "
              >
                WIN
              </div>
              <div class="card-body p-0">
                <p class="card-title m-0">17</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div
              class="card text-center"
              style="
                background-color: rgba(255, 149, 0, 0.638);
                border: 1px solid rgba(255, 119, 0, 0.633);
                color: white;
              "
            >
              <div
                class="card-header p-0"
                style="
                  background-color: rgba(255, 149, 0, 0.638);
                  border: 1px solid rgba(255, 119, 0, 0.633);
                "
              >
                PUSH
              </div>
              <div class="card-body p-0">
                <p class="card-title m-0">0</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div
              class="card text-center"
              style="
                background-color: rgba(255, 0, 0, 0.638);
                border: 1px solid rgba(255, 0, 0, 0.633);
                color: white;
              "
            >
              <div
                class="card-header p-0"
                style="
                  background-color: rgba(255, 0, 0, 0.638);
                  border: 1px solid rgba(255, 0, 0, 0.633);
                "
              >
                FAIL
              </div>
              <div class="card-body p-0">
                <p class="card-title m-0">1</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    tipsters: {
      type: Object,
    },
  },
  components: {},
  mounted() {},
  data() {
    return { show: true };
  },
  methods: {
    sendName(val) {
      return `${val.toLowerCase().split(" ")[0]}-${
        val.toLowerCase().split(" ")[1]
      }`;
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}

p {
  font-size: 12px;
}
a {
  font-size: 14px;
}
hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
table {
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
/* we will explain what these classes do next! */
</style>
