<template>
  <div>
    <Loading :loading="false" />
    <div class="container-fluid py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 mb-3"
            >
              <h6 class="m-0 text-white">
                {{ $t("TOP_PERFORMING_PREMIUM_TIPSTERS") }}
              </h6>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <tipsters-card
                  title="TOP_PREMIUM_TIPSTERS_THIS_WEEK"
                  :tipsters="tipsters"
                />
              </div>
              <div class="col-lg-6">
                <tipsters-card
                  title="TOP_PREMIUM_TIPSTERS_THIS_MONTH"
                  :tipsters="tipsters_week"
                />
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 mt-3 mb-3"
            >
              <h6 class="m-0 text-white">
                {{ $t("PREMIUM_TIPSTERS_LEADERBOARD") }}
              </h6>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <leader-card
                  title="TOP_PREMIUM_TIPSTERS_THIS_WEEK"
                  :tipsters="tipsters"
                />
              </div>
            </div>
          </div>
          <Countrise />
        </div>
      </div>
    </div>
    <!-- Main News Slider End -->
  </div>
</template>

<script>
import Countrise from "../components/Countrise.vue";
import LeaderCard from "../components/LeaderCard.vue";
import Loading from "../components/Loading.vue";
import TipstersCard from "../components/TipstersCard.vue";

export default {
  components: {
    Loading,
    TipstersCard,
    LeaderCard,
    Countrise,
  },
  mounted() {},
  data() {
    return {
      tipsters: [
        {
          rank: 1,
          name: "Netiphong Kanyala",
          profit: "+184.00",
        },
        {
          rank: 2,
          name: "RASMUS KOVALAINEN",
          profit: "+174.00",
        },
        {
          rank: 3,
          name: "CAUÃ£ ALMEIDA SILVA",
          profit: "+173.00",
        },
        {
          rank: 4,
          name: "BRANIMIR Ã…Â½IVKOVIÃ„Â€¡",
          profit: "+169.00",
        },
        {
          rank: 5,
          name: "CRISTIANO MONALDO",
          profit: "+164.00",
        },
      ],
      tipsters_week: [
        {
          rank: 1,
          name: "Netiphong Kanyala",
          profit: "+184.00",
        },
        {
          rank: 2,
          name: "RASMUS KOVALAINEN",
          profit: "+174.00",
        },
        {
          rank: 3,
          name: "CAUÃ£ ALMEIDA SILVA",
          profit: "+173.00",
        },
        {
          rank: 4,
          name: "BRANIMIR Ã…Â½IVKOVIÃ„Â€¡",
          profit: "+169.00",
        },
        {
          rank: 5,
          name: "CRISTIANO MONALDO",
          profit: "+164.00",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 12px;
}
a {
  font-size: 12px;
}
</style>
