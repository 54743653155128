<template>
  <div class="bg-light">
    <div
      class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
    >
      <b class="m-0 text-dark">
        {{ $t(title) }}
      </b>
    </div>
    <div class="row">
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
        >
          <p class="m-0 text-dark">☆</p>
        </div>
        <hr />
      </div>

      <div class="col-lg-7">
        <div
          class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
        >
          <p class="m-0 text-dark">
            {{ $t("TIPSTERS") }}
          </p>
        </div>
        <hr />
      </div>
      <div class="col-lg-4">
        <div
          class="d-flex align-items-center justify-content-between py-2 px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ $t("PROFIT") }}</p>
        </div>
        <hr />
      </div>
    </div>

    <div class="row" v-for="data in tipsters" :key="data.rank">
      <div class="col-lg-1">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ data.rank }}</p>
        </div>
        <hr />
      </div>
      <div class="col-lg-7">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <a
            :href="`/soccer-tipsters/${sendName(data.name)}`"
            class="m-0 text-dark"
            style="color: #1c50ed !important"
            >{{ data.name.toUpperCase() }}</a
          >
        </div>
        <hr />
      </div>
      <div class="col-lg-4">
        <div
          class="d-flex align-items-center justify-content-between px-4 mb-3"
        >
          <p class="m-0 text-dark">{{ data.profit }}</p>
        </div>
        <hr />
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    tipsters: {
      type: Object,
    },
  },
  components: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {
    sendName(val) {
      return `${val.toLowerCase().split(" ")[0]}-${
        val.toLowerCase().split(" ")[1]
      }`;
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 14px;
}
hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
</style>
